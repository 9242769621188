import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { faUser } from '@fortawesome/free-solid-svg-icons'
import cx from 'classnames'
import React from 'react'

import { toggleGamer } from '../../actions/switchUserMode'
import { MAX_LOCAL_BLASTERS } from '../../constants/constants'
import { useAppDispatch, useAppSelector } from '../../hooks'
import { selectCurrentGamers } from '../../selectors/current-play-selectors'

const GamerSwitch = () => {
  const dispatch = useAppDispatch()
  const currentGamers = useAppSelector(selectCurrentGamers)
  if (!currentGamers.length) {
    return null
  }
  const { isActive: isGamer0Active } = currentGamers[0]
  const activeOtherGamers = currentGamers.filter(
    ({ isActive }, index) => index > 0 && isActive
  ).length

  return (
    <div className="gamerSwitch">
      {currentGamers.map(({ gamerId, isActive }, gamerIndex) => {
        if (gamerIndex > MAX_LOCAL_BLASTERS - 1) {
          return null
        }
        const isDisabled = gamerIndex === 0 ? isActive && activeOtherGamers > 0 : !isGamer0Active
        const editButtonClassname = cx('pushButton', { active: isActive })
        return (
          <button
            key={`gamer-${gamerIndex}`}
            className={editButtonClassname}
            onClick={() => {
              dispatch(toggleGamer({ gamerIndex }))
            }}
            title={gamerId}
            disabled={isDisabled}
          >
            <FontAwesomeIcon icon={faUser as IconProp} size="sm" />
          </button>
        )
      })}
    </div>
  )
}

export default GamerSwitch
