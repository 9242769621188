import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { ChangeEvent } from 'react'
import { IconProp } from '@fortawesome/fontawesome-svg-core'

import { HOW_TO_PROMPT, QUICKSTART_URL, USER_GUIDE_URL } from '../../constants/constants'
import { VERSION, FIREBASE_AUDIENCE } from '../../constants/environment'
import { selectPrefs } from '../../selectors/session-selectors'
import CloseIcon from '../widgets/CloseIcon'
import ModalBackdrop from './ModalBackdrop'
import { useAppSelector } from '../../hooks'
import { onModalContainerRef } from '../../util/scrolling'

type Props = {
  onUpdateSettings: (isShowOnStartup: boolean) => void
  onClose: () => void
}
const QuickStartModal = ({ onUpdateSettings, onClose }: Props) => {
  const {
    quickStart: { isShowOnStartup: initialIsShowOnStartup },
  } = useAppSelector(selectPrefs)
  const [isShowOnStartup, setIsShowOnStartup] = React.useState(initialIsShowOnStartup)
  const onToggleShowOnStartup = (event: ChangeEvent<HTMLInputElement>) => {
    setIsShowOnStartup(event.target.checked)
  }
  const _onClose = () => {
    onUpdateSettings(isShowOnStartup)
    onClose()
  }
  const _showHelp = () => {
    window.open(USER_GUIDE_URL, '_blank')
  }

  return (
    <ModalBackdrop>
      <div className="helpModal" data-test ref={onModalContainerRef} tabIndex={0}>
        <CloseIcon onClose={_onClose} />
        <div className="modalHeading">
          <FontAwesomeIcon
            className="icon"
            size="lg"
            icon={faQuestionCircle as IconProp}
            title="Help"
          />
          {HOW_TO_PROMPT}
        </div>
        <iframe title="quickstart" src={QUICKSTART_URL} tabIndex={-1} />
        <div className="modalFooter">
          <button className="chatNewTrackButton" id="more" onClick={_showHelp}>
            More Info...
          </button>
          <div className="modalHeading small">
            v{VERSION} <i>{FIREBASE_AUDIENCE}</i>
          </div>
          <div>
            <div>
              <input
                type="checkbox"
                id="show-on-startup"
                checked={isShowOnStartup}
                onChange={onToggleShowOnStartup}
              />
              <label htmlFor="show-on-startup">Show on next visit</label>
            </div>
          </div>
        </div>
      </div>
    </ModalBackdrop>
  )
}

export default QuickStartModal
