import React from 'react'
// import { render } from 'react-dom'
import { createRoot } from 'react-dom/client'
import ReactGA from 'react-ga'
import { Provider } from 'react-redux'
import { BrowserRouter as Router } from 'react-router-dom'

import App from './components/App'
import './assets/css/index.scss'
import { NODE_GA } from './constants/environment'
import { store } from './reducers'
import * as serviceWorker from './util/serviceWorker'

const appInfo = {
  analytics: {
    gaID: NODE_GA,
    // hotjarID: REACT_APP_HOTJAR,
  },

  deviceSupport: {
    required: ['webAudio'],
    excluded: [],
    minimumSize: { width: 0, height: 0 },
    custom: [],
  },
}

ReactGA.initialize(NODE_GA)
const host = window.location.hostname.split('.')[0]
document.title = `LyricBlaster - ${host}`
const container = document.getElementById('root')
const root = createRoot(container!)
// root.render(<App tab="home" />);
root.render(
  <Provider store={store}>
    <Router>
      <App appInfo={appInfo} />
    </Router>
  </Provider>
)
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
